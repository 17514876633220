<template>
  <v-container fluid>

    <v-row>        
      <v-col
        cols="12"
      >
        <v-card
          dark
          outlined
        >
          <v-card-title class="secondary py-1 text-subtitle-1">
            <v-icon left dense> mdi-flag </v-icon>
            Events
            <v-spacer/>
          </v-card-title>
          <v-card-text class="pa-0">

            <v-data-table
              :headers="headers"
              :items="allEvents"
              single-select
              class="elevation-5"
            >
            <template v-slot:item.EventName="{ item }">
                {{ item.EventName ? item.EventName : 'No data available' }}
            </template>
            <template v-slot:item.PaymentProfile="{ item }">
                {{ item.PaymentProfile ? item.PaymentProfile : 'No data available' }}
            </template>
            <template v-slot:item.PrintingProfile="{ item }">
                {{ item.PrintingProfile ? item.PrintingProfile : 'No data available' }}
            </template>
            <template v-slot:item.ShowInPOS="{ item }">
                {{ item.ShowInPOS ? item.ShowInPOS : 'No data available' }}
            </template>
            <template v-slot:item.CardSurchargePercentage="{ item }">
                {{ item.CardSurchargePercentage ? item.CardSurchargePercentage : 'No data available' }}
            </template>
            <template v-slot:item.year="{ item }">
                {{ item.year ? item.year : 'No data available' }}
            </template>
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-spacer></v-spacer>
                <v-dialog
                  v-model="dialog"
                  max-width="500px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon left x-small>
                        mdi-plus
                      </v-icon>
                      Add Event
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h6 grey lighten-2">
                      {{ formTitle }}
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-text>
                      <v-container>
                       <v-form 
                          ref="form"
                          v-model="valid"
                          lazy-validation
                        >
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.EventName"
                              label="Event Name"
                              :rules="eventNameRules"
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12">
                            <label>Payment Profile</label>
                            <v-row class="ml-1">
                              <v-checkbox
                                v-model="editedItem.PaymentProfile"
                                label="Cash"
                                value="Cash"
                                class="mr-5"
                              ></v-checkbox>
                              <v-checkbox
                                v-model="editedItem.PaymentProfile"
                                label="Card"
                                value="Card"
                                class="mr-5"
                              ></v-checkbox>
                              <v-checkbox
                                v-model="editedItem.PaymentProfile"
                                label="Voucher"
                                value="Voucher"
                              ></v-checkbox>
                            </v-row>
                          </v-col>

                          <v-col cols="12">
                            <label>Printing Profile</label>
                            <v-row class="ml-1">
                              <div v-for="item in PrintingList" :key="item.id">
                                <v-checkbox
                                  v-model="editedItem.PrintingProfile"
                                  :label="item.name"
                                  :value="item.name"
                                  class="mr-5"
                                  :rules="eventPrintRules"
                                  required
                                ></v-checkbox>
                              </div>
                            </v-row>
                          </v-col>

                          <v-col cols="12"> 
                            <v-combobox
                              v-model="editedItem.ShowInPOS"
                              :items="POSTypes"
                              label="Show in POS"
                              :rules="[v => !!v || 'Show in POS is required']"
                              required
                            ></v-combobox>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.CardSurchargePercentage"
                              label="Card Surcharge Percentage"
                              type="number"
                              min="0"
                              :rules="eventPercentageRules"
                              required
                            ></v-text-field>
                          </v-col>
                          
                          <v-col cols="12">
                            <v-combobox
                              v-model="editedItem.Year"
                              :items="Years"
                              label="Year"
                              :rules="[v => !!v || 'Year is required']"
                              required
                            ></v-combobox>
                          </v-col>
                        </v-row>
                       </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5">Are you sure you want to delete user?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initialize"
              >
                Reset
              </v-btn>
            </template>
            </v-data-table>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {
  mapState,
  mapMutations
} from 'vuex'

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Event Name", value: "EventName"},
      { text: "Payment Profile", value: "PaymentProfile"},
      { text: "Printing Profile", value: "PrintingProfile"},
      { text: "ShowIn POS", value: "ShowInPOS"},
      { text: "Card Surcharge Percentage", value: "CardSurchargePercentage"},
      { text: "Year", value: "Year"},
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      EventName: '',
      PaymentProfile: [],
      PrintingProfile: '',
      ShowInPOS: '',
      CardSurchargePercentage: '',
      Year: '',
    },
    defaultItem: {
      EventName: '',
      PaymentProfile: [],
      PrintingProfile: '',
      ShowInPOS: 'No',
      CardSurchargePercentage: '',
      Year: '',
    },

    valid: true,
    eventNameRules: [
      v => !!v || 'Event Name is required',
    ],
    eventPrintRules: [
      v => !!v || 'Printing Profile is required',
    ],
    eventPercentageRules: [
      v => (v && v > 0) || 'Card Surcharge Percentage must be greater than 0'
    ],

    PrintingList: [
      {
        id: 1,
        name: "Print"
      },
      {
        id: 2,
        name: "Prompt"
      },
      {
        id: 3,
        name: "Bypass"
      }
    ],

    POSTypes: ['YES', 'NO'],
    Years: ['2023', '2022', '2021', '2020', '2019']
  }),

  computed: {
    ...mapState('filter', ['globalEventFilter', 'allEvents']),
    formTitle () {
      return this.editedIndex === -1 ? 'New Event' : 'Edit Event'
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },
  methods: {
    ...mapMutations('filter', ['setGlobalEventFilter', 'setAllEvents']),
    initialize () {
      this.desserts = [
        {
          EventName: 'St. Vincent 2018',
          PaymentProfile: 'Cash & Card & Voucher',
          PrintingProfile: 'Bypass',
          ShowInPOS: 'No',
          CardSurchargePercentage: '1.00',
          Year: '2022'
        },
        {
          EventName: 'Truffle Kerfuffle 18',
          PaymentProfile: 'Cash & Card',
          PrintingProfile: 'Prompt',
          ShowInPOS: 'Yes',
          CardSurchargePercentage: '1.20',
          Year: '2022'
        }
      ]
    },

    editItem (item) {
      console.log("edit object")
      console.log(item)

      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      }
    }
  }
}
</script>
<style scoped>
h1{
  color: #a09c94;
  font-family: sans-serif;
}
</style>